<!--
	A helper component for images that can load the optimal image format.
	Formats like WebP are better for performance but not all browsers
	support them. Using a <picture> tag with multiple <sources> allows
	for a browser to decide which format to use.

  For small images like icons, you're probably better off just using
  the basic format (or better yet, a vector svg). But for larger images
  this can really improve performance.

	This component relies on `vite-imagetools`. In order to use it,
	you must change your import structure to list all the formats
	you want to support, and to include the `as=picture` directive which
	will include the necessary information.

	```
	import myImage from '@/path/to/img.png?width=100&format=webp;png&as=picture'
	<Image image={myImage} alt="A cool image" />
	```

  For really large images, or for images that you don't want to look grainy
  on high-resolution screens like Retina displays, you can also specify
  multiple sizes and allow the browser to choose. For this, you need to specify
  `media`, which map media queries to size classes to image dimensions.

  ```
  import myImage from '@/path/to/img.png?width=500;750;1000&format=webp;png&as=picture'
  <Image image={myImage} alt="A cool image" media={{default: {'1x': 500, '1.5x': 750, '2x': 1000}}} />
  ```

  This also supports media queries, which can select different size classes for
  different media queries.

    ```
  import myImage from '@/path/to/img.png?width=500;750;1000;1500;2000&format=webp;png&as=picture'
  <Image image={myImage} alt="A cool image" media={{'(max-width: 1000px)': {'1x': 500, '1.5x': 750, '2x': 1000}, '(min-width: 1001px)': {'1x': 1000, '1.5x': 1500, '2x': 2000}}} />
  ```

  For the css background-image equivalent, see the <BackgroundImage> component.

	If you would like the images to be preloaded, set the `preload` property to
	true instead of manually using <link rel="preload">.

	Docs:
  - https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
  - https://github.com/JonasKruckenberg/imagetools/blob/main/docs/directives.md
  - https://www.bronco.co.uk/our-ideas/using-relpreload-for-responsive-images/
-->
<script context="module">
  // export function browserSupportsWebP() {
  //   // TODO: how fast is this check? Should we cache the result?
  //   try {
  //     return (
  //       document
  //         .createElement('canvas')
  //         .toDataURL('image/webp', 0.5)
  //         .indexOf('data:image/webp') === 0
  //     )
  //   } catch (err) {
  //     return false
  //   }
  // }

  function extractProps(array) {
    return array.reduce(
      (props, cur) => ({
        ...props,
        [cur.substr(-1)]: parseFloat(cur.substring(0, cur.length - 1)),
      }),
      {},
    )
  }

  export function splitSources(sources) {
    return sources
      .split(',')
      .map((s) => s.trim())
      .map((s) => s.split(' '))
      .map((s) => ({ src: s[0], ...extractProps(s.slice(1)) }))
  }

  export function getDescriptorsToUrls(imageSources) {
    return new Map(Array.from(imageSources).map(({ w, src }) => [w, src]))
  }

  function getSourceSet(rawsources, descriptors = {}) {
    const sources = splitSources(rawsources)

    if (Object.keys(descriptors).length === 0) {
      if (typeof sources === 'string') {
        return sources
      }
      return sources.map((s) => s.src).join(', ')
    }

    const descriptorsToUrls = getDescriptorsToUrls(sources)
    return Object.entries(descriptors)
      .map(
        ([descriptor, width]) =>
          `${descriptorsToUrls.get(width)} ${descriptor}`,
      )
      .join(', ')
  }

  // return { [{type, srcset, media?}] }
  export function generateSources(imageSources, media = {}) {
    if (Object.keys(media).length === 0) {
      return Object.entries(imageSources).map(([type, sources]) => ({
        type: `image/${type}`,
        srcset: getSourceSet(sources),
      }))
    }

    return Object.entries(imageSources).flatMap(([type, sources]) =>
      Object.entries(media).map(([mediaQuery, descriptors]) => ({
        type: `image/${type}`,
        srcset: getSourceSet(sources, descriptors),
        media: mediaQuery === 'default' ? null : mediaQuery,
      })),
    )
  }
</script>

<script>
  // An object rendered by vite-imagetools using the `?as=picture` query directive.
  // Can support multiple formats (`format=webp;png`) and sizes (`width=100;200;300`).
  export let image

  // A mapping object that maps media queries to directives to sizes. E.g.:
  // {
  //     '(max-width: 600px)': {
  //       '1x': 600,
  //       '2x': 1200,
  //     },
  //     '(min-width: 601px)': {
  //       '1x': 1200,
  //       '2x': 2400,
  //     },
  //     'default': {
  //       '1x': 1200,
  //       '2x': 2400,
  //     },
  // }
  export let media = {}

  export let fallback = null
  export let preload = false

  let sources = []
  $: if (typeof image !== 'string') {
    sources = generateSources(image.sources, media)
  }

  $: if (typeof image === 'string' && RAILS_ENV !== 'production')
    console.warn(
      `Passed an image url to Image. For responsive loading pass an image object with the \`&as=picture\` directive.\nURL: ${image}`,
    )
</script>

<style>
  img {
    width: var(--width);
    height: var(--height);
  }
</style>

<svelte:head>
  {#if preload}
    {#each sources as { type, srcset, media }}
      <link rel="preload" as="image" {type} {media} imagesrcset={srcset} />
    {/each}
    <link rel="preload" as="image" href={fallback || image.img?.src || image} />
  {/if}
</svelte:head>

{#if typeof image === 'string'}
  <!-- svelte-ignore a11y-missing-attribute -->
  <img src={image} {...$$restProps} />
{:else}
  <picture>
    {#each sources as { type, srcset, media }}
      <source {srcset} {media} {type} />
    {/each}
    <!-- svelte-ignore a11y-missing-attribute -->
    <img src={fallback || image.img?.src} {...$$restProps} />
  </picture>
{/if}
